<div class="headerTab">
    <!-- <div class="container py-0"> -->
    <div class="d-flex py-2 justify-content-between align-items-center">
        <!-- <mat-icon class="errorClr matIcon">location_on</mat-icon>
                <div class="location">
                    <p class="mb-0">Location</p>
                    <span class="text-white">
                        CarsDaily, Vadodara, Gujarat
                    </span>
                </div> -->
        <div class="app-sidebar-logo cursorPointer" (click)="goToHomePage()">
            <img src="../../../assets/images/CarsDailyWhite.png" class='h-70px app-sidebar-logo-default' />
        </div>
        <div class="d-flex align-items-center position-relative">
            <div class="cart cursorPointer position-relative" (click)="wishlist()">
                <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 26 26">
                    <path fill="#fff" fill-rule="evenodd"
                        d="M18.922 7.202c-1.61-2.116-4.73-1.775-6.321.354a.75.75 0 0 1-1.201 0c-1.595-2.128-4.711-2.47-6.322-.354-.534.7-.828 1.587-.828 2.585 0 1.654.945 3.22 2.364 4.66 1.41 1.43 3.185 2.634 4.642 3.57.44.282 1.049.281 1.487 0v-.001l.348-.225c1.314-.854 2.83-1.912 4.09-3.142 1.27-1.238 2.21-2.584 2.485-4.004.235-1.205-.021-2.496-.743-3.443Zm1.193-.91C17.965 3.47 14.193 3.836 12 5.975c-2.194-2.137-5.964-2.508-8.115.319-.75.983-1.135 2.197-1.135 3.494 0 2.238 1.27 4.164 2.795 5.712 1.536 1.558 3.435 2.838 4.9 3.78h.001a2.914 2.914 0 0 0 3.108-.001c.116-.074.234-.15.355-.23 1.334-.867 2.95-1.989 4.32-3.325 1.362-1.33 2.55-2.938 2.91-4.792.306-1.576-.013-3.314-1.024-4.638Z" />
                </svg>
                @if(wishListData.length > 0){
                <p class="cartCount">{{wishListData.length}}</p>
                }
            </div>
            <div class="cart cursorPointer mx-2 position-relative" (click)="cartRedirect()">
                <img src="../../../assets/images/bags-shopping.png" width="26" />
                @if(cartData.length > 0){
                <p class="cartCount">{{cartData.length}}</p>
                }
            </div>
            <!-- <span class="cursorPointer" (click)="login()">
                <img src="../../../assets/images/profile.png" alt="profile" class="w-100 profileimg">
            </span> -->
        </div>
    </div>
    <!-- </div> -->
</div>