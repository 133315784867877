import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../../component/header/header.component';
import { FooterComponent } from '../../component/footer/footer.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-front-layout',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, FooterComponent, CommonModule],
  templateUrl: './front-layout.component.html'
})
export class FrontLayoutComponent {

  pathName: any;
  constructor(
    public router: Router
  ) {
    this.pathName = this.router.url;
   }
}
