import { Routes } from '@angular/router';
import { AuthenticationLayoutComponent } from './layout/authentication-layout/authentication-layout.component';
import { DemoLayoutComponent } from './layout/demo-layout/demo-layout.component';
import { Paths } from './enums';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { FrontLayoutComponent } from './layout/front-layout/front-layout.component';

import { Capacitor } from '@capacitor/core';
import { SplashScreenComponent } from './component/front/splash-screen/splash-screen.component';
import { IntroductionComponent } from './component/front/introduction/introduction.component';

const initialRoutes = Capacitor.getPlatform() === 'web' ?
    {
        path: '',
        component: FrontLayoutComponent,
        loadChildren: () =>
            import('./component/front/front.module').then(
                (m) => m.FrontModule
            ),
    } : {
        path: '',
        component: SplashScreenComponent
    };

export const routes: Routes = [
    {
        path: Paths.authentication,
        component: AuthenticationLayoutComponent,
        loadChildren: () =>
            import('./component/authentication/authentication.module').then(
                (m) => m.AuthenticationModule
            ),
    },
    {
        path: Paths.application,
        component: AppLayoutComponent,
        loadChildren: () =>
            import('./component/application/application.module').then(
                (m) => m.ApplicationModule
            ),
    },
    {
        path: Paths.demo,
        component: DemoLayoutComponent,
        loadChildren: () =>
            import('./component/demo/demo.module').then(
                (m) => m.DemoModule
            )
    },
    {
        path: Paths.main,
        component: FrontLayoutComponent,
        loadChildren: () =>
            import('./component/front/front.module').then(
                (m) => m.FrontModule
            ),
    },
    {
      path: Paths.introduction,
      component: IntroductionComponent
    },
    initialRoutes
];
