import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-authentication-layout',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './authentication-layout.component.html'
})
export class AuthenticationLayoutComponent {

}
