import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { LocalStorage, Paths } from '../../enums';
import { CartOutputData, CartServiceProxy, SelectActiveCartOutput, SelectActiveWishListOutput, WishListOutputData, WishListServiceProxy } from '../../service-proxies';
import { Subscription } from 'rxjs';
import { CommonService, LocalStorageService } from '../../services';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './header.component.html'
})
export class HeaderComponent {

  cartData: CartOutputData[] = [];
  tabChangeDataGet: Subscription;
  tabChangeDataGetWishList: Subscription;
  wishListData: WishListOutputData[] = [];

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private cartServiceProxy: CartServiceProxy,
    private commonService: CommonService,
    private wishListServiceProxy: WishListServiceProxy,) {

    this.tabChangeDataGet = this.commonService.getData().subscribe(data => {
      this.cartData = data;
    });
    this.tabChangeDataGetWishList = this.commonService.getWishListData().subscribe(data => {
      this.wishListData = data;
    });
  }

  /**
   * This method is called once, just after the constructor called.
   * It called active cart list API for get data.
   * 
   * @memberof HeaderComponent
   */
  ngOnInit(): void {
    if (this.localStorageService.getData(LocalStorage.accessToken)) {
      this.getCartData();
    }
  }

  /**
   * Retrieves an active list of cart data.
   * 
   * @memberof CartComponent
   */
  getCartData() {
    try {
      this.cartServiceProxy.selectActive().subscribe((response: SelectActiveCartOutput) => {
        if (response.status === 1) {
          this.cartData = response.data;
          this.cartData = this.cartData.filter((a: any) => a.cart_list_type === 'PRODUCT');
        } else {
          this.cartData = [];
        }
        this.getWishList();
      });
    } catch (error) {
      console.error('Error getting cart:', error);
    }
  }

  /**
 * Retrieves an active list of product data.
 * 
 * @memberof WishlistComponent
 */
  getWishList() {
    try {
      this.wishListServiceProxy.selectActive().subscribe((response: SelectActiveWishListOutput) => {
        if (response.status === 1) {
          this.wishListData = response.data;
        } else {
          this.wishListData = [];
        }
      });
    } catch (error) {
      console.error('Error getting product:', error);
    }
  }

  /**
   * Redirects to the login page.
   * 
   * @memberof HeaderComponent
   */
  // login() {
  //   this.router.navigate([`${Paths.authentication}/${Paths.login}`]);
  // }

  /**
   * Redirects to the cart page.
   * 
   * @memberof HeaderComponent
   */
  cartRedirect() {
    this.router.navigate(["/" + Paths.main + "/" + Paths.cart]);
  }

  /**
   * Redirects to the wishlist page.
   * 
   * @memberof HeaderComponent
   */
  wishlist() {
    this.router.navigate([`${Paths.main}/${Paths.wishlist}`]);
  }

  /**
   * Redirects to the home page.
   * 
   * @memberof HeaderComponent
   */

  goToHomePage() {
    this.router.navigate(["/" + Paths.main]);
  }
}
