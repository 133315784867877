import { ChangeDetectorRef, Component, NgZone, afterNextRender } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { StatusBar, Style } from '@capacitor/status-bar';
import { App as CapacitorApp } from '@capacitor/app';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { ScreenOrientation } from '@capacitor/screen-orientation';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgxSpinnerModule, MatProgressBarModule, MatProgressSpinnerModule, CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  isLoad = false;
  rows: any = [];

  constructor(
    cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {
    StatusBar.setBackgroundColor({ color: "#FB493B" }).catch(() => { });
    StatusBar.setStyle({ style: Style.Dark }).catch(() => { });
    afterNextRender(() => {
      this.zone.run(() => {
        setTimeout(() => {
          console.log("Capacitor.getPlatform() ", Capacitor.getPlatform());
          if (Capacitor.getPlatform() !== 'web') {
            SplashScreen.hide();
          }
          this.isLoad = true;
          cdr.detectChanges();
        }, 100);
      });
    });

    if (Capacitor.getPlatform() !== 'web') {
      CapacitorApp.addListener('backButton', ({ canGoBack }) => {
        if (!canGoBack) {
          CapacitorApp.exitApp();
        } else {
          window.history.back();
        }
      });
    }
  }

  ngOnInit() {
    // this.lockPortrait();
    if (Capacitor.getPlatform() !== 'web') {
      this.lockPortrait();
    }
  }

  async lockPortrait() {
    try {
      await ScreenOrientation.lock({ orientation: 'portrait' });
    } catch (error) {
      console.error('Failed to lock screen orientation:', error);
    }
  }

  // Method to lock orientation to landscape
  async lockLandscape() {
    try {
      await ScreenOrientation.lock({ orientation: 'landscape' });
    } catch (error) {
      console.error('Failed to lock screen orientation:', error);
    }
  }

  // Method to unlock orientation
  async unlockOrientation() {
    try {
      await ScreenOrientation.unlock();
    } catch (error) {
      console.error('Failed to unlock screen orientation:', error);
    }
  }
}
