import { Component, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-demo-layout',
  standalone: true,
  imports: [RouterOutlet, SharedModule, CommonModule],
  templateUrl: './demo-layout.component.html',
  styleUrl: './demo-layout.component.scss'
})
export class DemoLayoutComponent {

  isMenuOpen: boolean = true;
  currentUrl: any;
  innerWidth: number = 0;

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.isMenuOpen = innerWidth > 991.98;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.isMenuOpen = innerWidth > 991.98;
  }

  menuToggle(flag: any) {
    this.isMenuOpen = flag;
  }
  
}
