import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { API_BASE_URL } from './service-proxies/service-proxies';
import { CheckNullUndefinedService, IMAGE_BASE_URL } from './services';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { LoaderInterceptor, TokenInterceptor } from './interceptor';
import {
  BannerServiceProxy, UserServiceProxy, UserTypeServiceProxy, CountryServiceProxy, StateServiceProxy, CityServiceProxy,
  CategoryServiceProxy, BlogServiceProxy, UserApplyForEmploymentServiceProxy, ProductServiceProxy, ServicesServiceProxy,
  DiscountCouponServiceProxy, CategoryWiseDiscountServiceProxy, ProductWiseDiscountServiceProxy, ServiceWiseDiscountServiceProxy,
  ProductStockServiceProxy, CarBrandServiceProxy, CarModelServiceProxy, FuelTypeServiceProxy, UserCarServiceProxy, CarServiceProxy,
  CarEnquiryServiceProxy, UserAddressServiceProxy, CartServiceProxy, OrderServiceProxy, OrderAddressServiceProxy, OrderItemServiceProxy,
  WishListServiceProxy, CouponCodeServiceProxy, CategoryWiseCouponCodeServiceProxy, ProductWiseCouponCodeServiceProxy, ServiceWiseCouponCodeServiceProxy,
  UserServiceServiceProxy, ServiceBookingServiceProxy,
  ServicePictureServiceProxy,
  RatingReviewServiceProxy,
  NotificationServiceProxy,
  ServicePackageServiceProxy
} from './service-proxies';
import { CommonFunctions } from './service-proxies/common-functions';
import { DatePipe } from '@angular/common';
import { CouponServiceProxy } from './service-proxies/services/coupon.service';
import { initializeApp } from 'firebase/app';
initializeApp(environment.firebaseConfig);

export function GetRemoteServiceBaseUrl(): string {
  return environment.baseURL;
}

export function GetRemoteImageBaseUrl(): string {
  return environment.imageURL;
}

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    provideRouter(routes),
    provideClientHydration(),
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    { provide: API_BASE_URL, useFactory: GetRemoteServiceBaseUrl },
    { provide: IMAGE_BASE_URL, useFactory: GetRemoteImageBaseUrl },
    DatePipe,
    CheckNullUndefinedService,
    CommonFunctions,
    UserTypeServiceProxy,
    UserServiceProxy,
    UserAddressServiceProxy,
    UserCarServiceProxy,
    BannerServiceProxy,
    CountryServiceProxy,
    StateServiceProxy,
    CityServiceProxy,
    CategoryServiceProxy,
    BlogServiceProxy,
    UserApplyForEmploymentServiceProxy,
    ProductServiceProxy,
    DiscountCouponServiceProxy,
    CategoryWiseDiscountServiceProxy,
    ProductWiseDiscountServiceProxy,
    ServiceWiseDiscountServiceProxy,
    ServicesServiceProxy,
    ProductStockServiceProxy,
    CouponCodeServiceProxy,
    CategoryWiseCouponCodeServiceProxy,
    ProductWiseCouponCodeServiceProxy,
    ServiceWiseCouponCodeServiceProxy,
    FuelTypeServiceProxy,
    CarBrandServiceProxy,
    CarModelServiceProxy,
    CarServiceProxy,
    CarEnquiryServiceProxy,
    CartServiceProxy,
    WishListServiceProxy,
    OrderServiceProxy,
    OrderAddressServiceProxy,
    OrderItemServiceProxy,
    CouponServiceProxy,
    UserServiceServiceProxy,
    ServiceBookingServiceProxy,
    CouponServiceProxy,
    ServicePictureServiceProxy,
    RatingReviewServiceProxy,
    NotificationServiceProxy,
    ServicePackageServiceProxy

  ]
};