
<car-daily-top-nav [isMenuOpen]="isMenuOpen" (menuToggle)="menuToggle($event)"></car-daily-top-nav>
<div class="main">
    <div class="side-menu" [ngClass]="{'menuClose': !isMenuOpen}">
        <car-daily-side-nav [isMenuOpen]="isMenuOpen" (menuToggle)="menuToggle($event)"></car-daily-side-nav>
    </div>
    <div class="content">
        <div class="main-content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<div (click)="menuToggle(false)" [ngClass]="{'drawer-overlay': isMenuOpen}"></div>