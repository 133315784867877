<div class="footer">
    <div class="footer-bottom-links">
        <div class="footer-flex">
            <div class="footer-flex-item">
                <a routerLink="/main" routerLinkActive="nav-active" [routerLinkActiveOptions]="{ exact: true }">
                    <i class="fa-solid fa-house"></i>
                </a>
            </div>
            <div class="footer-flex-item">
                <a routerLink="/main/carService" routerLinkActive="nav-active"
                    [routerLinkActiveOptions]="{ exact: true }">
                    <i class="fa-solid fa-screwdriver-wrench"></i>
                </a>
            </div>
            <div class="footer-flex-item">
                <a routerLink="/main/oldCarDeals" routerLinkActive="nav-active"
                    [routerLinkActiveOptions]="{ exact: true }">
                    <i class="fa-solid fa-car"></i>
                </a>
            </div>
            <div class="footer-flex-item">
                <a routerLink="/main/accessories" routerLinkActive="nav-active"
                    [routerLinkActiveOptions]="{ exact: true }">
                    <i class="fa-solid fa-spray-can-sparkles"></i>
                </a>
            </div>
            <div class="footer-flex-item">
                <a routerLink="/main/profile" routerLinkActive="nav-active" [routerLinkActiveOptions]="{ exact: true }">
                    <i class="fa-solid fa-user"></i>
                </a>
            </div>
        </div>
    </div>
</div>