@if(router.url !== '/introduction'){
<app-header *ngIf="pathName != '/splash'"></app-header>
}
<!-- <div class="container-fluid"> -->
<router-outlet></router-outlet>
<!-- </div> -->
<!-- @if(router.url !== '/' && router.url !== '/main'){ -->
@if(router.url !== '/introduction'){
<app-footer *ngIf="pathName != '/splash'"></app-footer>
}
<!-- } -->