<div id="header"></div>
<div id="content">
  @if(isLoad) {
  <router-outlet></router-outlet>
  }
  @else {
  <div id="appLoading">
    <span id="loader"></span>
  </div>
  }
</div>
<div id="footer">&nbsp;</div>
<ngx-spinner bdColor="rgba(210, 210, 210,0.5)" size="medium" color="#fff" type="ball-scale-multiple">
  <div class="tbl-spinner ">
    <mat-progress-spinner color="primary" [diameter]="40" mode="indeterminate">
    </mat-progress-spinner>
  </div>
</ngx-spinner>